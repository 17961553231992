.textEffect_comeFromUp {
    overflow: hidden;

    &.in {
        & > .overflowZone {
            transition: all 1000ms ease-in-out;
            transition-delay: 0ms;
            
            @media screen and (max-width:991px) {
                margin-left: 0px;
                opacity: 1; 
            }
            @media screen and (min-width:991px) {
                margin-top: 0px;
            }
        }
    }
    &.out {
        & > .overflowZone {
            transition: all 0ms ease;
            transition-delay: 0ms;
            
            @media screen and (max-width:991px) {
                margin-left: 20px;
                opacity: 0;
            }
            @media screen and (min-width:991px) {
                margin-top: -60px;
            }
        }
    }
}

.textEffect_comeFromUp250 {
    overflow: hidden;

    &.in {
        & > .overflowZone {
            transition: all 1000ms ease-in-out;
            transition-delay: 250ms;
            
            @media screen and (max-width:991px) {
                margin-left: 0px;
                opacity: 1; 
            }
            @media screen and (min-width:991px) {
                margin-top: 0px;
            }
        }
    }
    &.out {
        & > .overflowZone {
            transition: all 0ms ease;
            transition-delay: 0ms;
            
            @media screen and (max-width:991px) {
                margin-left: 20px;
                opacity: 0;
            }
            @media screen and (min-width:991px) {
                margin-top: -60px;
            }
        }
    }
}


.fadeIn {
    &.in {
        transition: all 1000ms ease-in-out;
        transition-delay: 250ms;
        opacity: 1;
        margin-top: 0px;
    }
    &.out {
        transition: all 0ms ease;
        transition-delay: 0ms;
        opacity: 0;
        margin-top: 50px;
    }
}
